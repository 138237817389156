import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Table from "../components/Table";
import {BigLoader} from '../components/BigLoader'
import { Card, CardContent } from "@mui/material";
import { authAxios } from "../config/authAxios";
const PremiumUpgradeLogs = () => {
    const [logs, setLogs] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await authAxios.get("/premium-upgrades");
        console.log('response',response)
        setLogs(response.data.response);
      } catch (err) {
        setError("Failed to fetch logs");
      } finally {
        setLoading(false);
      }
    };

    fetchLogs();
  }, []);

  const column =[
    {
        headerName: "adminId",
        property: "adminId",
        minWidth:200
      },
      {
        headerName: "adminRole",
        property: "adminRole",
        minWidth:300
      },
      {
        headerName: "userId",
        property: "userId",
        minWidth:700
      },
      {
        headerName: "planId",
        property: "planId",
        minWidth:700
      },
      {
        headerName: "price",
        property: "price",
        minWidth:700
      },
      {
        headerName: "startAt",
        property: "startAt",
        minWidth:700
      },
      {
        headerName: "endAt",
        property: "endAt",
        minWidth:700
      },
  ]
  return (
    <>
      <Header selectedNumber={4}></Header>
      <div className="container-2">
        <Card sx={{mt:4,bgcolor:"whitesmoke"}}>
          <CardContent>
          <h3>Premium Upgrade Logs</h3>
          </CardContent>
        </Card>
        <Table data={logs} viewButton ={false} column={column} loading={loading} totalPages={logs?.totalPages} />
      </div>
      {loading && <BigLoader/>}
    </>
  );
};

export default PremiumUpgradeLogs;
