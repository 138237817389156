import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { TiDelete } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import { Toast } from "../components/Toast";
import { addAdminPermissionAction } from "../redux/reducers/admin/addPermission";


const EditPermission = ({ open, setOpen ,adminId}) => {
  const [snake, setSnake] = useState(false);
  const [permission,setPermission] = useState([])
  const { message, status, loading } = useSelector(
    (state) => state.addPermission
  );
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    handleAddPermission(e.target.value.toString().toLowerCase())
  }

  const handleAddPermission = (value) => {
    if (!value) {
      return;
    }
    if(permission.includes(value)){
      return
    }
    setPermission((prev)=>[...prev,value])
  
  }

  
  const submitPermissions = async () => {
    let data = {
      permission:permission,
      adminId:adminId
    }
  await dispatch(addAdminPermissionAction(data));
    setSnake(true);
    setOpen(false);
  };


  return (
    <>
      <div>
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
          <Alert sx={{ justifyContent: "center" }} severity="error">
            This Field is Required to add the permission.
          </Alert>
            <DialogTitle>
              <h4>Add Admin Permissions</h4>
            </DialogTitle>
            <DialogContent>
              <Box display="flex" flexDirection="column" gap={1} p={2}>
                <Box>
                  { permission.length > 0 && permission.map((item,index) => <span  key={index} style={{marginRight:"10px",fontSize:"15px"}}>{item} <TiDelete style={{fontSize:"1.2rem",cursor:"pointer"}} onClick={() => setPermission((prev)=>prev.filter((i)=>i !== item))} /></span>) }
                </Box>
                <Box marginTop={2}>
                <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                label="Permissions"
                name="Permission"
                onChange={handleInputChange}
              >
                <MenuItem value={"meta"}>Meta</MenuItem>
                 {/* <MenuItem value={"notification"}>Notification</MenuItem> */}
                 {/* <MenuItem value={"schedule-notification"}>Schedule Notification</MenuItem> */}
                <MenuItem value={"all-users"}>All Users</MenuItem>
                <MenuItem value={"view-users"}>View Users</MenuItem>
                <MenuItem value={"view-admin"}>View Admin</MenuItem>
                <MenuItem value={"reported-users"}>Reported Users</MenuItem>
                <MenuItem value={"premium-history"}>Premium History</MenuItem>
                <MenuItem value={"active-premium"}>Active Premium</MenuItem>
                <MenuItem value={"active-users"}>Active User</MenuItem>
                <MenuItem value={"deleted-users"}>Deleted Users</MenuItem>
                <MenuItem value={"freeplan-logs"}>Free Plan Logs</MenuItem>
                <MenuItem value={"auth-logs"}>Auth Logs</MenuItem>
                <MenuItem value={"refer"}>Refer</MenuItem>
                <MenuItem value={"admin"}>Admin</MenuItem>
                <MenuItem value={"slowDown-list"}>slowDown-list</MenuItem>
                <MenuItem value={"create-blog"}>Create Blog</MenuItem>
                <MenuItem value={"all-blogs"}>All Blogs</MenuItem>
                <MenuItem value={"edit-blog"}>edit Blog</MenuItem>
                <MenuItem value={"user-stats"}>User Stats</MenuItem>
                <MenuItem value={"reported-userLeader-board"}>reported user Leaderboard</MenuItem>
              </Select>
            </FormControl>
                </Box>
               
                {/* <TextField
                sx={{marginTop:2}}
                
                  fullWidth
                  name="permission"
                  label="Enter Permission"
                /> */}
              </Box>
            </DialogContent>
            <DialogActions>
              <button
                type="submit"
                onClick={submitPermissions}
                className="btn-submit"
                style={{ width: "120px" }}
                disabled={loading}
              >
                {loading ? <Loader /> : "Submit"}
              </button>

              <button
                className="btn-submit"
                disabled={loading}
                style={{ width: "120px", background: "red" }}
                onClick={() => setOpen(false)}
              >
                Close
              </button>
            </DialogActions>
        </Dialog>
      </div>
      <Toast
        open={snake}
        setOpen={setSnake}
        message={message}
        status={status}
      />
    </>
  );
};

export default EditPermission;
