import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios } from "../../../config/authAxios";
const initialState = { message: "", data: [], status: null, loading: false };


export const userKpiInterestAction = createAsyncThunk(
  "get-user-kpi-interest",
  async ( data, { rejectWithValue }) => {
    try {
      const response = await authAxios.post(`/userInterestCount`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userKpiInterestSlice = createSlice({
  name: "get-user-kpi-interest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userKpiInterestAction.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(userKpiInterestAction.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload?.data;
      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });

    builder.addCase(userKpiInterestAction.rejected, (state, action) => {
      state.loading = false;

      state.status = action?.payload?.status;
      state.message = action?.payload?.message;
    });
  },
});

export const userKpiInterestReducer = userKpiInterestSlice.reducer;