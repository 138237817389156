import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const renderCustomLabel = ({
  data,
  cx,
  cy,
  midAngle,
  outerRadius,
  value,
  index,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = outerRadius + 20;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="rgb(25, 218, 225)"
      textAnchor={x > cx ? "start" : "end"}
      fontSize="14"
    >
      {`${data[index].name}: ${value}`}
    </text>
  );
};

const KpiPieChart = ({ data, xKey = "date", yKey, yLabel }) => {
  if (!data || !yKey) {
    console.error("Data or yKey is missing!");
    return null;
  }
  const filteredData = data.filter((entry) => entry.value > 1000);

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ flex: 7 }}>
        <PieChart width={850} height={500}>
          <Pie
            data={filteredData}
            dataKey="value"
            startAngle={360}
            endAngle={0}
            cx={400}
            cy={250}
            outerRadius={180}
            fill="#8884d8"
            isAnimationActive={false}
            label={(props) => renderCustomLabel({ ...props, data:filteredData })}
          >
            {filteredData?.map((entry, index) => (
              <Cell key={`cell-${index}`} />
            ))}
          </Pie>
        </PieChart>
      </div>

      <div style={{ flex: 3, paddingLeft: "20px" }}>
        <h3>Interest Breakdown</h3>
        <ul
            style={{
                listStyleType: "none",
                padding: 0,
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px", 
            }}
        >
            {data?.map((entry, index) => (
                <li
                    key={`legend-item-${index}`}
                    style={{
                        margin: "5px 0",
                        color: "#8884d8",
                        fontSize: "14px",
                    }}
                >
                    <strong>{entry.name}</strong>: {entry.value}
                </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default KpiPieChart;