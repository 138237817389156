import React, { useState } from 'react';
import "../../Styles/home.css";
import "../../Styles/alluser.css";
import KpiLineChart from "./KpiLineChart.jsx";
import {convertSecToTime} from '../../helper/agefromMilisecCal';

const MaleFemaleAvgDurationStats = ({data}) => {
    const [selectedMetric, setSelectedMetric] = useState('totalTimeSpentOnApp');


    const getChartData = () => {
        return data?.totalChatDateWise?.map(item => ({
            date: item.date,
            value: item[selectedMetric]
        })) || [];
    };

    return (
        <div className="live-chart-div margin-left w-100">
            <div className="line-chart-section">
                <h3>User Avg Interaction</h3>
                <div className=''>
                    <KpiLineChart data={getChartData()} xKey="date" yKey="value" yLabel="Time Spent" />
                </div>
            </div>
            <div className='kpi-section'>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('avgMaleToMaleChatsDuration')}>
                    <h4 className='p10'>Avg M to M chat Dur.</h4>
                    <p className="kpi-points">{(convertSecToTime(data?.avgMaleToMaleChatsDuration) )|| 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('avgFemaleToFemaleChatsDuration')}>
                    <h4 className='p10'>Avg F to F chat Dur.</h4>
                    <p className="kpi-points">{convertSecToTime(data?.avgFemaleToFemaleChatsDuration) || 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('avgMaleToFemaleChatsDuration')}>
                    <h4 className='p10'>Avg M to F chat Dur.</h4>
                    <p className="kpi-points">{convertSecToTime(data?.avgMaleToFemaleChatsDuration) || 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('avgMaleToMaleCallsDuration')}>
                    <h4 className='p10'>Avg M to M call Dur.</h4>
                    <p className="kpi-points">{convertSecToTime(data?.avgMaleToMaleCallsDuration) || 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('avg M To F Calls Dur.')}>
                    <h4 className='p10'>Avg M to F call Dur.</h4>
                    <p className="kpi-points">{convertSecToTime(data?.avgMaleToFemaleCallsDuration) || 0}</p>
                </div>
                <div className="home-top-box w-100 pointer" onClick={() => setSelectedMetric('avg F to F Calls Dur.')}>
                    <h4 className='p10'>Avg call Dur. F to F</h4>
                    <p className="kpi-points">{convertSecToTime(data?.avgFemaleToFemaleCallsDuration) || 0}</p>
                </div>
            </div>
        </div>
    );
}

export default MaleFemaleAvgDurationStats;